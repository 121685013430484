/* eslint-disable jsx-a11y/label-has-associated-control */
import { useIntl } from 'react-intl'
import { useEffect, useCallback, useState } from 'react'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import Header from '../../components/Header'
import {
  fetchEnrolmentClaim,
  getEnrolmentRequestDetails,
  getUserDetails,
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
  getEnrolmentRequestComments,
} from '@mzaednica/core'
import Footer from '../../components/Footer'
import ProfileIcon from '../../img/profile_menu_gray.svg'
import CameraGray from '../../img/camera_gray.svg'
import MultipleMediaField from '../../components/Inputs/MultipleMediaField'
import findImageWithQuality from '../../utils/findImageWithQuality'
import ProcessWhite from '../../img/process_white.svg'
import FinishedWhite from '../../img/finished_white.svg'
import parseDate from '../../utils/parseDate'
import parseTime from '../../utils/parseTime'
import { LANGUAGES } from '../../i18n/settings/languages'
import { getLanguage } from '../../utils/languageUtils'

const initialValues = {
  content: '',
  files: [], // Changed to accept files as an array
}

const validationSchema = Yup.object({
  content: Yup.string().required('Comment is required'),
})

const EnrolmentRequestDetails = ({
  fetchEnrolmentClaim,
  enrolmentRequestDetails,
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
  comments,
  userDetails,
  language,
}) => {
  const { id } = useParams()
  const history = useHistory()
  const [request, setRequest] = useState(null)

  const fetchRequest = useCallback(
    async (id) => {
      await fetchEnrolmentClaim(id)
    },
    [fetchEnrolmentClaim],
  )

  useEffect(() => {
    fetchRequest(id)
    listEnrolmentRequestComments({ enrolment_request: id })
  }, [fetchRequest, id, listEnrolmentRequestComments])

  useEffect(() => {
    setRequest(enrolmentRequestDetails.enrolmentRequestData)
  }, [enrolmentRequestDetails.enrolmentRequestData])

  const submitComment = useCallback(
    async (values, { resetForm }) => {
      if (!userDetails.userData) {
        history.push('login')
      } else if (!userDetails.userData.profileComplete) {
        history.push('completeProfile')
      } else {
        const commentData = {
          ...values,
          enrolment_request: id,
          commentType: 'comment',
        }
        delete commentData.files
        try {
          await createEnrolmentRequestComment(values.files[0], commentData)
          resetForm()
          listEnrolmentRequestComments({ enrolment_request: id })
        } catch (e) {
          console.error(e)
          // Handle error if needed
        }
      }
    },
    [
      userDetails,
      id,
      createEnrolmentRequestComment,
      listEnrolmentRequestComments,
      history,
    ],
  )

  const { formatMessage } = useIntl()
  return (
    <>
      <Header />
      <div className="container max-w-4xl mx-auto mb-4 font-montserratRegular">
        <div className="text-2xl text-center mt-10 uppercase">
          {formatMessage({ id: 'detailsRequestEnrollment' })}
        </div>
        <div className="shadow rounded-lg">
          <table className="mt-10 min-w-full leading-normal">
            <thead></thead>
            <tbody>
              {request && (
                <>
                  <tr>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: 'enrolment' })}
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {request.educational_facility && 
                       ((language === LANGUAGES.SQ && request.educational_facility.name_sq) || 
                        request.educational_facility.name)}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: 'status' })}
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {request.status
                        ? formatMessage({
                            id: request.status,
                          }).toLocaleUpperCase()
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: 'meetingStartTime' })}
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {parseDate(request.meetingStartTime)}{' '}
                      {formatMessage({ id: 'in' })}{' '}
                      {parseTime(request.meetingStartTime)}
                    </td>
                  </tr>
                  {request.fields &&
                    request.fields.length > 0 &&
                    request.fields.map((field, idx) => {
                      let component = ''
                      switch (field.__component) {
                        case 'fields.text-field':
                          component = field.value
                          break
                        case 'fields.dropdown-field':
                          component = field.value
                          break
                        case 'fields.date-field':
                          component = field.value
                          break
                        case 'fields.boolean-field':
                          component = field.value
                            ? formatMessage({ id: 'yes' })
                            : formatMessage({ id: 'no' })
                          break
                        case 'fields.rich-text-field':
                          component = field.value
                          break
                        case 'fields.multiple-media-field':
                          component = field.value
                            ? field.value.map((val, valIdx) => (
                                <div key={valIdx}>
                                  {val.mime.startsWith('image/') ? (
                                    <a
                                      href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                    >
                                      <img
                                        alt="someAlt"
                                        className="w-20 h-20"
                                        src={`${process.env.RAZZLE_API_URL}${val.url}`}
                                      />
                                    </a>
                                  ) : (
                                    <>
                                      <a
                                        className="text-blue"
                                        href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                      >{`${val.name}`}</a>
                                      <a
                                        href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                        download
                                        target="_blank"
                                        className="text-blue ml-10"
                                        rel="noreferrer"
                                      >
                                        {formatMessage({ id: 'downloadDoc' })}
                                      </a>
                                    </>
                                  )}
                                  <br />
                                </div>
                              ))
                            : ''
                          break
                      }
                      return (
                        <tr key={idx}>
                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            {language === LANGUAGES.SQ && field.fieldLabel_sq ? field.fieldLabel_sq : field.fieldLabel}
                          </td>
                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            {component}
                          </td>
                        </tr>
                      )
                    })}
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {formatMessage({ id: 'timeEditRequest' })}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {moment(request.updated_at).format('DD.MM.YYYY HH:mm a')}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <section className="px-5 md:px-0 pt-7">
          {/* Step 1 */}
          <div className="flex h-full">
            <div className="flex flex-col">
              <div className="w-94 h-auto inline-flex items-center justify-center">
                <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                  <img
                    src={
                      request && request.user && request.user.profilePicture
                        ? `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                            request.user.profilePicture,
                          )}`
                        : ProfileIcon
                    }
                    alt="Problem icon"
                    className="object-cover w-16 h-16 p-1 rounded-full"
                  />
                </figure>
              </div>
              {comments.isFetched && !!comments.listData.length && (
                <div className="w-94 flex h-full min-h-40 justify-center">
                  <div className="bg-blue w-2.5"></div>
                </div>
              )}
            </div>
            <div className="ml-5 md:ml-10">
              <h6 className="text-base font-semibold text-lightGray3">
                {formatMessage({ id: 'submittedЕnrolmentBy' })}
              </h6>
              <h4 className="font-semibold text-darkGray1 text-lg">
                {request &&
                  request.user &&
                  `${request.user.firstName} ${request.user.familyName}`}
              </h4>
              <h6 className="text-base font-semibold text-lightGray3">
                {request && moment(request.created_at).format('DD.MM.YYYY')}
              </h6>
            </div>
          </div>

          {!comments.isFetching &&
            comments.isFetched &&
            comments.listData.map((comment, idx) => {
              const isLast = idx === comments.listData.length - 1
              let statusIcon = ProcessWhite
              switch (comment.newStatus) {
                case 'closed':
                  statusIcon = FinishedWhite
                  break
              }
              return (
                <div className="flex h-full" key={idx}>
                  <div className="flex flex-col">
                    <div className="w-94 h-auto inline-flex items-center justify-center">
                      {comment && (
                        <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                          <img
                            src={
                              comment.user && comment.user.profilePicture
                                ? `${
                                    process.env.RAZZLE_API_URL
                                  }${findImageWithQuality(
                                    comment.user.profilePicture,
                                  )}`
                                : ProfileIcon
                            }
                            alt="Problem icon"
                            className="object-cover w-16 h-16 p-1 rounded-full"
                          />
                        </figure>
                      )}
                      {comment.commentType === 'status_change' && (
                        <figure className="bg-blue rounded-full w-68 h-68 inline-flex justify-center items-center flex-shrink-0">
                          <img
                            src={statusIcon}
                            alt="Problem icon"
                            width="46"
                            height="auto"
                          />
                        </figure>
                      )}
                    </div>
                    {!isLast && (
                      <div className="w-94 flex h-full min-h-40 justify-center">
                        <div className="bg-blue w-2.5"></div>
                      </div>
                    )}
                  </div>
                  <div className="ml-5 md:ml-10">
                    <h4 className="font-semibold text-darkGray1 text-lg">
                      {comment.user.firstName} {comment.user.familyName}
                    </h4>
                    <h6 className="text-base font-semibold text-lightGray3">
                      {moment(comment.created_at).format('DD.MM.YYYY')}
                    </h6>
                    <p className="text-base font-semibold text-lightGray3 my-3">
                      {comment.content}
                    </p>
                    {comment.commentType === 'comment' &&
                      !!comment.files.length && (
                        <div className="flex flex-row justify-start items-start mt-5 mb-5 px-4 md:px-0">
                          {comment.files.map((file, fileIdx) => (
                            <img
                              key={fileIdx}
                              src={`${
                                process.env.RAZZLE_API_URL
                              }${findImageWithQuality(file, 'large')}`}
                              alt="Logo"
                              className="md:w-64 md:h-64 mr-3"
                            />
                          ))}
                        </div>
                      )}
                  </div>
                </div>
              )
            })}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitComment}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-7">
                <Field name="files">
                  {({ field, form: { setFieldValue } }) => (
                    <MultipleMediaField
                      field={field}
                      setFieldValue={setFieldValue}
                      multiple={false}
                      id="files"
                    />
                  )}
                </Field>
                <div className="flex flex-row">
                  <label
                    htmlFor="files"
                    className="flex flex-col justify-center"
                  >
                    <img
                      src={CameraGray}
                      alt="Camera icon"
                      width="54"
                      height="auto"
                    />
                  </label>
                  <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-full md:ml-3 mt-4 md:mt-0">
                    <Field
                      name="content"
                      type="text"
                      className="w-auto text-lg md:text-xl font-semibold bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                      placeholder={formatMessage({ id: 'enterComment' })}
                    />
                    <div className="flex w-auto justify-end items-center">
                      <button
                        type="submit"
                        className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                        disabled={isSubmitting}
                      >
                        {formatMessage({ id: 'send' })}
                      </button>
                    </div>
                  </label>
                </div>
                <ErrorMessage name="content">
                  {(msg) => <div className="text-red text-sm mt-1">{msg}</div>}
                </ErrorMessage>
              </Form>
            )}
          </Formik>
        </section>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  enrolmentRequestDetails: getEnrolmentRequestDetails(state),
  userDetails: getUserDetails(state),
  comments: getEnrolmentRequestComments(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  fetchEnrolmentClaim,
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnrolmentRequestDetails)
