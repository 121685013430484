import { getLanguage as coreGetLanguage } from '@mzaednica/core'
import { LANGUAGES } from '../i18n/settings/languages'
import isBrowser from './isBrowser'

/**
 * Wrapper for getLanguage that handles server-side rendering
 * @param {Object} state - Redux state
 * @returns {string} The current language
 */
export const getLanguage = (state) => {
  try {
    if (!isBrowser()) {
      // Default to MK on the server
      return LANGUAGES.MK
    }
    return coreGetLanguage(state)
  } catch (error) {
    console.error('Error getting language:', error)
    return LANGUAGES.MK
  }
} 