import { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Footer from '../components/Footer'
import ReactMarkdown from 'react-markdown'
import termsTxt from '../files/TermsAndConditions.md'
import termsTxtSq from '../files/TermsAndConditions_sq.md'
import Header from '../components/Header'
import { LANGUAGES } from '../i18n/settings/languages'
import { getLanguage } from '../utils/languageUtils'

const TermsAndConditions = ({ language }) => {
  const [markdown, setMarkdown] = useState('')

  const fetchMarkdown = useCallback(async () => {
    const res = await fetch(language === LANGUAGES.MK ? termsTxt : termsTxtSq)
    const text = await res.text()
    setMarkdown(text)
  }, [language])

  useEffect(() => {
    fetchMarkdown()
  }, [fetchMarkdown])

  return (
    <>
      <Header />
      <ReactMarkdown className="container mx-auto pt-5 pb-10 md">
        {markdown}
      </ReactMarkdown>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default connect(mapStateToProps)(TermsAndConditions)
