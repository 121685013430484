import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  listCategories,
  listInterests,
  getProfile,
  listMunicipalities,
} from '@mzaednica/core'
import LoginModal from './components/Modals/LoginModal/LoginModal'
import EmailLoginModal from './components/Modals/LoginModal/EmailLoginModal'
import ForgotPasswordModal from './components/Modals/ForgotPasswordModal'
import SignupModal from './components/Modals/SignUpModal/SignupModal'
import EmailSignupModal from 'components/Modals/SignUpModal/EmailSignupModal'
import { FacebookProvider } from 'react-facebook'
import isBrowser from './utils/isBrowser'

const Layout = ({
  getProfile,
  listMunicipalities,
  listInterests,
  listCategories,
  children,
}) => {
  const history = useHistory()

  useEffect(() => {
    const fetch = async () => {
      let response = null
      try {
        response = await getProfile()
        if (response && !response.profileComplete) {
          history.push('/completeProfile')
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [getProfile, history])

  useEffect(() => {
    listMunicipalities()
    listInterests()
    listCategories()
  })

  return (
    <>
      {isBrowser() ? (
        <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID}>
          {children}
          <LoginModal />
          <EmailLoginModal></EmailLoginModal>
          <ForgotPasswordModal></ForgotPasswordModal>
          <SignupModal />
          <EmailSignupModal />
        </FacebookProvider>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  getProfile,
  listMunicipalities,
  listInterests,
  listCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
