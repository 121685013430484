const LANG = 'LANG'

export const languages = {
  MK: 'mk',
  SQ: 'sq',
}

export const saveLanguage = ({ language }) => {
  localStorage.setItem(LANG, language)
}

export const getDefaultLanguage = () => 'sq'

export const getLanguage = () => {
  const savedLanguage = localStorage.getItem(LANG)
  if (savedLanguage) {
    return savedLanguage
  }

  return 'sq'
}
