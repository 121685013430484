import { connect } from 'react-redux'
import { useState, useEffect, useCallback } from 'react'
import ReactMarkdown from 'react-markdown'

import Footer from '../components/Footer'
import dataDeletionTxt from '../files/DataDeletion.md'
import dataDeletionTxtSq from '../files/DataDeletion.md'
import Header from '../components/Header'
import { LANGUAGES } from '../i18n/settings/languages'
import { getLanguage } from '../utils/languageUtils'

const DataDeletion = ({ language }) => {
  const [markdown, setMarkdown] = useState('')

  const fetchMarkdown = useCallback(async () => {
    const res = await fetch(
      language === LANGUAGES.MK ? dataDeletionTxt : dataDeletionTxtSq,
    )
    const text = await res.text()
    setMarkdown(text)
  }, [language])

  useEffect(() => {
    fetchMarkdown()
  }, [fetchMarkdown])

  return (
    <>
      <Header />
      <ReactMarkdown className="container mx-auto pt-5 pb-10 md">
        {markdown}
      </ReactMarkdown>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default connect(mapStateToProps)(DataDeletion)
