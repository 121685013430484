/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik'
import { connect } from 'react-redux'
import { getLanguage } from '../../utils/languageUtils'

import FieldLabel from '../../atoms/FieldLabel'
import { LANGUAGES } from '../../i18n/settings/languages'

const DropdownField = ({
  fieldName,
  fieldLabel,
  fieldlabel_sq,
  isRequired,
  options,
  language,
}) => (
  <>
    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
      <FieldLabel
        label={language === LANGUAGES.MK ? fieldLabel : fieldlabel_sq}
        isRequired={isRequired}
      />
      <div className="inline-flex items-center justify-end">
        <Field
          as="select"
          name={fieldName}
          className="w-auto text-lg md:text-xl text-lightGray3 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
          placeholder="..."
        >
          <option value=""></option>
          {options.values.map((option) => (
            <option value={option.value} key={option.value}>
              {language === LANGUAGES.MK ? option.value_mk : option.value_sq}
            </option>
          ))}
        </Field>
      </div>
    </label>
  </>
)

const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default connect(mapStateToProps)(DropdownField)
