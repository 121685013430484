import { useIntl } from 'react-intl'

import { Field, Form, Formik, ErrorMessage } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { useHistory, useParams } from 'react-router'
import MaskedInput from 'react-text-mask'

import Footer from '../../components/Footer'
import Header from '../../components/Header'
import MediaField from '../../components/Inputs/MediaField'
import EditIcon from '../../img/edit.svg'
import {
  createSubventionClaim,
  fetchSubvention,
  getSubventionDetails,
  embgRegex,
  bankIdRegex,
  uploadFile,
} from '@mzaednica/core'
import { getLanguage } from '../../utils/languageUtils'
import withAuthentication from '../../utils/withAuthentication'
import Docs from '../../components/Inputs/Docs'
import { LANGUAGES } from '../../i18n/settings/languages'

const embgMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const bankIdMask = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]
const SubventionRequest = ({
  fetchSubvention,
  subventionDetails,
  createSubventionClaim,
  language,
}) => {
  let { id } = useParams()
  const history = useHistory()
  const { formatMessage } = useIntl()

  const { subventionData } = subventionDetails || {}

  const [initialValues, setInitialValues] = useState({})
  const [validationSchema, setValidationSchema] = useState(yup.object())

  const getInitialData = useCallback(async () => {
    const subvention = await fetchSubvention(id)
    if (subvention) {
      const newInitValues = {}
      const objectShape = {}
      subvention.fields.forEach((field) => {
        newInitValues[field.fieldName] = null
        let fieldValidator
        switch (field.__component) {
          case 'fields.text-field':
            fieldValidator = yup.string().ensure()
            if (field.isRequired) {
              fieldValidator = fieldValidator.required(formatMessage({ id: 'requiredField' }))
            }
            switch (field.validation) {
              case 'email':
                fieldValidator = fieldValidator.email(formatMessage({ id: 'invalidemail' }))
                break
              case 'embg':
                fieldValidator = fieldValidator.matches(
                  embgRegex,
                  formatMessage({ id: 'invalidEMBG' }),
                )
                break
              case 'bankId':
                fieldValidator = fieldValidator.matches(
                  bankIdRegex,
                  formatMessage({ id: 'invalidBankingInfo' }),
                )
                break
            }
            break
          case 'fields.media-field':
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: 'requiredField' }))
                .typeError(formatMessage({ id: 'requiredField' }))
            }
            break
          case 'fields.multiple-media-field':
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: 'requiredField' }))
                .typeError(formatMessage({ id: 'requiredField' }))
            }
            break
          case 'fields.dropdown-field':
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: 'requiredField' }))
                .typeError(formatMessage({ id: 'requiredField' }))
            }
            break
        }
        if (fieldValidator) {
          objectShape[field.fieldName] = fieldValidator
        }
      })
      setValidationSchema(
        yup.object().shape({
          ...objectShape,
          termsAndConditions: yup
            .boolean()
            .oneOf(
              [true],
              language === LANGUAGES.SQ
                ? 'Kjo fushë është e detyrueshme'
                : 'Ова поле е задолжителнo',
            ),
        }),
      )
      setInitialValues(newInitValues)
    }
  }, [fetchSubvention, id, language, formatMessage])

  useEffect(() => {
    getInitialData()
  }, [getInitialData])

  const submitForm = useCallback(
    async (values) => {
      if (!subventionData || !subventionData.fields) {
        return
      }
      const dataFields = []
      for (let i = 0; i < subventionData.fields.length; ++i) {
        if (
          subventionData.fields[i].__component === 'fields.multiple-media-field'
        ) {
          continue
        }
        const field = subventionData.fields[i]
        dataFields.push({
          ...field,
          value: values[field.fieldName],
          id: undefined,
        })
      }

      for (let i = 0; i < subventionData.fields.length; ++i) {
        if (
          subventionData.fields[i].__component !== 'fields.multiple-media-field'
        ) {
          continue
        }
        for (let fld of values[subventionData.fields[i].fieldName]) {
          const formData = new FormData()
          formData.append('files', fld)
          const files = await uploadFile(formData)
          const dataField = dataFields.find((dataField) => dataField.pid === i)
          if (dataField) {
            const newDataField = {
              ...subventionData.fields[i],
              value: [...dataField.value, files[0].id],
              pid: i,
              id: undefined,
            }
            dataFields.splice(dataFields.indexOf(dataField), 1, newDataField)
          } else {
            dataFields.push({
              ...subventionData.fields[i],
              value: [files[0].id],
              pid: i,
              id: undefined,
            })
          }
        }
      }

      await createSubventionClaim({
        subvention: id,
        fields: dataFields,
      })
      history.push('/profile#myServices')
    },
    [createSubventionClaim, history, id, subventionData?.fields, formatMessage],
  )

  return (
    <>
      <Header />
      <section className="bg-lightGray4">
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form className="container w-840 max-w-full md:pt-103 md:pb-48">
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
                {formatMessage({ id: 'inquiry' })}
              </h2>
              <div className="container px-4 md:px-0 mt-10">
                {subventionData && subventionData.fields && subventionData.fields.map((fld) => {
                  let field
                  switch (fld.__component) {
                    case 'fields.text-field':
                      field = (
                        <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                          <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                            {fld.fieldLabel_sq}
                            {fld.isRequired ? '*' : ''}
                          </span>
                          <div className="inline-flex items-center justify-between">
                            <Field type="text" name={fld.fieldName}>
                              {({ field }) => {
                                const classes =
                                  'w-auto text-lg md:text-xl text-lightGray3 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0'
                                let inputProps = {
                                  ...field,
                                  className: classes,
                                  placeholder: '...',
                                }
                                switch (fld.validation) {
                                  case 'email':
                                    inputProps.type = 'email'
                                    break
                                  case 'embg':
                                    inputProps.mask = embgMask
                                    break
                                  case 'bankId':
                                    inputProps.mask = bankIdMask
                                    break
                                }
                                return inputProps.mask ? (
                                  <MaskedInput {...inputProps} />
                                ) : (
                                  <input {...inputProps} />
                                )
                              }}
                            </Field>
                            <img
                              alt="someAlt"
                              src={EditIcon}
                              width="18"
                              height="auto"
                            />
                          </div>
                        </label>
                      )
                      break
                    case 'fields.media-field':
                      field = (
                        <Field name={fld.fieldName}>
                          {({ field, form: { setFieldValue } }) => (
                            <MediaField
                              field={field}
                              setFieldValue={setFieldValue}
                              fieldLabel={fld.fieldLabel_sq}
                            />
                          )}
                        </Field>
                      )
                      break
                    case 'fields.rich-text-field':
                      field = (
                        <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                          <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                            {fld.fieldLabel_sq}
                            {fld.isRequired ? '*' : ''}
                          </span>
                          <div className="inline-flex items-center justify-between">
                            <Field
                              name={fld.fieldName}
                              component="textarea"
                              className="text-area w-full"
                              rows="6"
                              placeholder={formatMessage({ id: 'max300' })}
                            />
                          </div>
                        </label>
                      )
                      break
                    case 'fields.dropdown-field':
                      field = (
                        <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                          <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                            {fld.fieldLabel_sq}
                            {fld.isRequired ? '*' : ''}
                          </span>
                          <div className="inline-flex items-center justify-between">
                            <Field
                              as="select"
                              name={fld.fieldName}
                              className="w-auto text-lg md:text-xl text-lightGray3 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
                              placeholder="..."
                            >
                              <option value=""></option>
                              {fld.options.values.map((val, idx) => (
                                <option key={idx} value={val.value}>
                                  {val.label}
                                </option>
                              ))}
                            </Field>
                            <img
                              alt="someAlt"
                              src={EditIcon}
                              width="18"
                              height="auto"
                            />
                          </div>
                        </label>
                      )
                      break
                  }
                  return (
                    <>
                      {field}
                      <div className="text-red">
                        <ErrorMessage name={fld.fieldName} />
                      </div>
                    </>
                  )
                })}
                {subventionData && subventionData.fields && (
                  <Docs
                    fields={subventionData.fields.filter(
                      (field) =>
                        field.__component === 'fields.multiple-media-field',
                    )}
                  />
                )}
                <>
                  <label>
                    <Field type="checkbox" name="termsAndConditions" />
                    {formatMessage({ id: 'iAgreeMuniCopyIDPersonalData' })}
                  </label>
                  <div className="text-red">
                    <ErrorMessage name="termsAndConditions" />
                  </div>
                </>
              </div>

              <div className="flex justify-end mt-10 md:mt-14 px-4 md:px-0">
                <button
                  type="submit"
                  className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-lg font-semibold rounded-xl text-white bg-blue disabled:opacity-20 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
                  disabled={isSubmitting}
                >
                  {formatMessage({ id: 'send' })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  subventionDetails: getSubventionDetails(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  fetchSubvention,
  createSubventionClaim,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(SubventionRequest),
)
